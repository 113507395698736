import React, {useState} from 'react'
import SignIn from '../components/Signin'
import Navbar from '../components/Navbar'

const SigninPage = () => {
  
    return (
        <>
            
            <SignIn />
        </>
    )
}

export default SigninPage;
